import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/tour/tour.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Tour: Dashbot for Commerce Chatbots`}</h2>
    <h2>{`Increase Conversions and Monetization`}</h2>
    <p>{`Dashbot has a variety of reports to better understand user behavior and track conversions versus abandonment.`}</p>
    <h3>{`Conversion Goal Tracking`}</h3>
    <p>{`With Dashbot’s Conversion Goal Tracking, you can track any type of goal, including purchases.`}</p>
    <p>{`Quickly see how many users purchase versus abandon, the percentages overtime, as well as the common paths that lead to purchases, and the transcripts in which they occur.`}</p>
    <p>{`Dive deeper into the flows to get a better understanding of user behavior, where the chatbot may be breaking down, and improve the overall user experience.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/vhpqVbuwnCw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h3>{`Goal Funnels`}</h3>
    <p>{`With Dashbot Goal Funnels, you can track how many users make it through the various steps,or stages, of the funnel.`}</p>
    <p>{`This is a quick way to track purchase conversions.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/03/03163150/goal-funnel.png",
        "alt": null
      }}></img></p>
    <h3>{`Conversation Paths`}</h3>
    <p>{`With Dashbot’s Conversation Paths, you can quickly navigate the common flows through your chatbot or voice skill to see drop off and abandonment as well as the paths that lead to conversion.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/04/30163009/conversation-flow.gif",
        "alt": null
      }}></img></p>
    <h2>{`Track External Revenue Events`}</h2>
    <p>{`With Dashbot custom Event tracking, you can track events that occur outside of the chatbot – including revenue events, referrals, signups, and more.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/bVMLk9Bb3cI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Optimize Response Effectiveness`}</h2>
    <p>{`With Dashbot’s Phrase Clustering, you can identify where the chatbot may be breaking down through mishandled or unhandled Intents.`}</p>
    <p>{`The information can be used to train your NLP engine to improve response effectiveness and user satisfaction.`}</p>
    <h3>{`Identify mishandled and unhandled Intents`}</h3>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/Gn86aDSWD9k?feature=oembed" width="1200" />
    <h3>{`Reduce failure rate`}</h3>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/e4IGl7d-49Q?feature=oembed" width="1200" />
    <h2>{`Segment your audience to better understand key users`}</h2>
    <p>{`With Dashbot Audience Builder, you can segment your users based on demographics, usage, or additional meta data.`}</p>
    <p>{`Identify top user segments and behaviors; acquire similar users through look-a-like campaigns; or re-engage through broadcast messaging.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/2M-QgFrYrCc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Re-target through broadcast messaging`}</h2>
    <p>{`With Dashbot Audience Builder, you can re-engage users through audience segmentation and targeted broadcast messaging.`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/VqCA7IRaQFI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Get real-time notifications to insert live agents`}</h2>
    <p>{`Get real-time alerts of sessions going awry, and insert a live agent to help lead users to conversion.`}</p>
    <p>{`Dashbot APIs integrate with 3rd party live agent, chat platforms, including Genesys`}</p>
    <iframe width="760" height="427" src="https://www.youtube.com/embed/BV4F1X-WoVI" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    <h2>{`Build a chatbot from live transcripts`}</h2>
    <hr></hr>
    <p>{`Are you looking to build a chatbot? Do you have historical live agent transcripts?`}</p>
    <p>{`Dashbot’s Phrase Clustering can process live agent transcripts to identify common user messages and Intents as well as agent responses to kick start chatbot development. The data can be used to train your NLP engine.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/02/16181540/message-processing-600x467.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      